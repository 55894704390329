import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


const StickyButtons = () => {
    
  return (
    <div>
      {/* Left side sticky buttons */}
      <div style={leftSideStyles}>
       
        <a href="https://wa.me/7847842626" style={iconStyle}>
          <FontAwesomeIcon icon={faWhatsapp} style={iconTextStyle} />
        </a>
      </div>

     
    </div>
  );
};

// Inline styles for the buttons


// Inline styles for the left side container (bottom-left)
const leftSideStyles = {
  position: "fixed",
  bottom: "20px",
  left: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
};

// Inline styles for the right side container (bottom-right)

const iconStyle = {
    display: "block",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "#ff6600",
    textAlign: "center",
    lineHeight: "50px",
    fontSize: "24px",
    marginBottom: "15px",
    transition: "transform 0.3s",
    cursor: "pointer",
  };
  
  // Style for the icons to be white
  const iconTextStyle = {
    color: "#fff", // Icon color is set to white
    fontSize: "24px", // Font size of the icon
  };
  
  

export default StickyButtons;

import React from 'react';

// Sample data for specialists and testimonials


const testimonials = [
  {
    id: 1,
    text: "Visited Nanda Nandi Goshala and was truly impressed by their exceptional care for the cows. Their dedication and compassion are commendable. Special thanks to Brinda Ma’am for her kindness. A must-visit for anyone who values excellent animal care.",
    name: "vikash mohapatra"
  },
  {
    id: 2,
    text: "Nanda Nandi Goshala offers genuine care for cows and bulls. Their commitment to this holy cause is remarkable. Please support their efforts.",
    name: "Vimal sharma"
  },
  {
    id: 3,
    text: "I visited Nanda Nandi Gaushala last week. They care for 40 Gir breed cows from Gujarat, ensuring they are healthy and happy. A great place dedicated to preserving our native breeds.",
    name: "Laxmikant Mishra"
  },
  {
    id: 4,
    text: "Dubey ji, my family and I enjoyed our visit. Your passion for cow care is evident. The personalized care and effective treatment from Dr. Verma were incredibly helpful. Thank you!",
    name: "Virat"
  },
  {
    id: 5,
    text: "The best quality ghee and gomutra I've used. Sri Ramakant runs the Goshala with true care for the animals. Highly recommend for Ayurvedic products and health improvements.",
    name: "Siddharth P"
  },

  {
    id: 6,
    text: "A place devoted to the utmost care and well-being of the cows,  as it is one of the most revered traditional symbols of our Hindu religion.",
    name: "Dipika Contractor"
  },

  {
    id: 7,
    text: "They are doing gods work. The animals are happy and healthy. One must support such causes.",
    name: "Arshpreet Kaur"
  },

  {
    id: 8,
    text: "Really feel amazing to see sewa of cow @ Nanda Nandi gaushala....namho namha of dubey sir such A sewa of cow's like family members 🙏🙏🙏🙏🙏🙏",
    name: "satya devra"
  },
];

const TestimonialsPage = () => {
  return (
    <div style={styles.page}>
  
      <div style={styles.testimonialsSection}>
        <h2 style={styles.heading}>Testimonials</h2>
        <div style={styles.cardsContainer}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} style={styles.card}>
              <p style={styles.cardText}>{testimonial.text}</p>
              <p style={styles.cardAuthor}>- {testimonial.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  page: {
    padding: '20px',
    boxSizing: 'border-box',
    backgroundColor: '#f4f4f4',
  },
  specialistsSection: {
    backgroundColor: '#fff',
    textAlign:'center',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
  testimonialsSection: {
    padding: '20px',
  },
  heading: {
    fontSize: '28px',
    marginBottom: '20px',
    textAlign: 'center',
    color:'#ff6600'
    
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
  },
  card: {
    backgroundColor: 'white',
    color:'white',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: 'black',
    maxWidth: '300px',
    width: '100%',
    boxSizing: 'border-box',
    border: '2px solid #ff6600',
          
    boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
  },
  specialistImage: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: '15px',
  },
  cardContent: {
    textAlign: 'center',
  },
  cardTitle: {
    fontSize: '20px',
    margin: '10px 0',
  },
  cardDetails: {
    fontSize: '16px',
    margin: '5px 0',
    color: 'white',
  },
  cardText: {
    fontSize: '16px',
    marginBottom: '10px',
    color:'black',
  },
  cardAuthor: {
    fontSize: '18px',
    fontWeight:'600',
    color: '#026817',
    textAlign: 'center',
  },
  '@media (max-width: 768px)': {
    specialistImage: {
      width: '120px',
      height: '120px',
    },
    cardsContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
};

export default TestimonialsPage;

import React, { useState } from 'react';

// Sample images
const images = [
  
  {
    original: '/home/first_cow_nanda.jpeg',
    thumbnail: '/home/first_cow_nanda.jpeg',
    description: 'Photo 1',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.10 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.10 PM (1).jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.10 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.10 PM.jpeg',
    description: 'Photo 3',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM (1).jpeg',
    description: 'Photo 4',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM (2).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM (2).jpeg',
    description: 'Photo 5',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM.jpeg',
    description: 'Photo 6',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.45 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.45 PM (1).jpeg',
    description: 'Photo 7',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.46 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.46 PM (1).jpeg',
    description: 'Photo 8',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.46 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.46 PM.jpeg',
    description: 'Photo 9',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.59.02 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.59.02 PM (1).jpeg',
    description: 'Photo 10',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.58.59 PM (3).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.58.59 PM (3).jpeg',
    description: 'Photo 11',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.35.47 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.35.47 PM.jpeg',
    description: 'Photo 12',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM (1).jpeg',
    description: 'Photo 13',
  },
  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM.jpeg',
    description: 'Photo 14',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.59.01 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.59.01 PM.jpeg',
    description: 'Photo 15',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.59.02 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.59.02 PM.jpeg',
    description: 'Photo 16',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 7.03.12 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 7.03.12 PM (1).jpeg',
    description: 'Photo 17',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 7.03.13 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 7.03.13 PM.jpeg',
    description: 'Photo 18',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 7.03.16 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 7.03.16 PM (1).jpeg',
    description: 'Photo 19',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 7.03.17 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 7.03.17 PM (1).jpeg',
    description: 'Photo 20',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 7.03.17 PM (2).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 7.03.17 PM (2).jpeg',
    description: 'Photo 21',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 7.03.18 PM (1).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 7.03.18 PM (1).jpeg',
    description: 'Photo 22',
  },

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 7.03.18 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 7.03.18 PM.jpeg',
    description: 'Photo 23',
  },

  

  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM.jpeg',
    description: 'Photo 24',
  },


  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.58.59 PM (2).jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.58.59 PM (2).jpeg',
    description: 'Photo 25',
  },


  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.59.00 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.59.00 PM.jpeg',
    description: 'Photo 26',
  },


  {
    original: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM.jpeg',
    thumbnail: '/gallery/WhatsApp Image 2024-08-23 at 6.46.36 PM.jpeg',
    description: 'Photo 27',
  },

  {
    original: '/gallery/img_1.jpeg',
    thumbnail: '/gallery/img_1.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_2.jpeg',
    thumbnail: '/gallery/img_2.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_3.jpeg',
    thumbnail: '/gallery/img_3.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_4.jpeg',
    thumbnail: '/gallery/img_4.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_5.jpeg',
    thumbnail: '/gallery/img_5.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_7.jpeg',
    thumbnail: '/gallery/img_7.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_8.jpeg',
    thumbnail: '/gallery/img_8.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_9.jpeg',
    thumbnail: '/gallery/img_9.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_10.jpeg',
    thumbnail: '/gallery/img_10.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_11.jpeg',
    thumbnail: '/gallery/img_11.jpeg',
    description: 'Photo 2',
  },
  {
    original: '/gallery/img_12.jpeg',
    thumbnail: '/gallery/img_12.jpeg',
    description: 'Photo 2',
  },

];

const PhotoGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Our Journey with Cows</h1>
      <hr/>
      
      <div style={styles.galleryContainer}>
        {images.map((image, index) => (
          <div key={index} style={styles.imageContainer}>
            <img
              src={image.thumbnail}
              alt={image.description}
              style={styles.thumbnail}
              onClick={() => openModal(image.original)}
            />
       
          </div>
        ))}
      </div>

      {selectedImage && (
        <div style={styles.modal} onClick={closeModal}>
          <img src={selectedImage} alt="Full Size" style={styles.modalImage} />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    textAlign:'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  heading :{
    fontSize: '2rem',
    marginBottom: '20px',
    textAlign: 'center',
    color: '#ff6600',
   /* Required for pseudo-element positioning */
  },


  galleryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent:'center'
  },
  imageContainer: {
    width: '250px',
   
    textAlign: 'center',
    cursor: 'pointer',
  },
  thumbnail: {
    width: '100%',
    height:'200px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  caption: {
    marginTop: '5px',
    fontSize: '1rem',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalImage: {
    maxWidth: '90%',
    maxHeight: '80%',
    borderRadius: '8px',
  },
};

export default PhotoGallery;

import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import BookYourSeva from "./BookYourSeva"
import DonationForm from "./DonationForm"


const Donate = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDonateNowClick = () => {
    setFormVisible(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bimva32', 'template_7nn9bok', e.target, '-J5aznDBbENygx1cJ')
      .then((result) => {
        console.log(result.text);
        setSubmitted(true);
        alert("Thank you for your donation! We have received your details.");
      }, (error) => {
        console.log(error.text);
        alert("There was an error processing your donation. Please try again.");
      });

    e.target.reset(); // Reset the form after submission
  };

  return (

    <div>
  <BookYourSeva/>
<div style={styles.container} >

<div style={{ ...styles.rowContainer, flexDirection: isMobile ? 'column' : 'row' }}>
  <div style={styles.donationInfo}>
    <h2 style={styles.heading}>ALL DONATIONS ARE EXEMPT FROM INCOME TAX UNDER SEC. 80 G OF INCOME TAX ACT</h2>
    <p style={styles.text}>DON’T HESITATE! JOIN HANDS AND HELP TODAY</p>
    <p style={styles.text}>
   
    </p>
    <p style={styles.text}>DONATE and FEEL A SENSE OF GRATITUDE</p>
    <p style={styles.textUpi}>SCAN the QR CODE and transfer using UPI/PAYTM/PHONEPE/GPAY etc.</p>
    <p style={styles.text}>You can even transfer using NET BANKING to the below-mentioned Bank Account details:</p>
    <p style={styles.text}>
      <strong>Bank Account Details :</strong><br />
      SRI MADHAV SEVA SANGH (R.)<br />
      SBI A/C No:  43266595755 - IFSC: 0017606<br />
      Registration number ABL-4-00118-2024-25
     
    </p>
  </div>
  <div style={{ ...styles.qrCodeContainer, marginTop: isMobile ? '20px' : '0' }}>
    <img src="\AboutUs\WhatsApp Image 2024-08-21 at 7.54.35 PM.jpeg" alt="QR Code" style={styles.qrCode} />
  </div>
</div>
<button onClick={handleDonateNowClick} style={styles.donateButton}>Send Your Details</button>

{formVisible && (
  <div style={styles.formContainer}>
    <h2 style={styles.formHeading}>Donate Online</h2>
    <DonationForm/>
    

    

    {submitted && <p style={styles.successMessage}>Thank you for your donation! We have received your details.</p>}
  </div>
)}
</div>
    </div>
   
  );
};

const styles = {
  container: {
    padding: '10px',
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    textAlign:'center',
    
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '20px',
  },
  donationInfo: {
    flex: '0 0 60%',
    paddingRight:'0px'
  

  },
  qrCodeContainer: {
    flex: '0 0 40%',
    textAlign: 'center',
  },
  qrCode: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  donateButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#ff6600',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontSize: '16px',
  },
  formContainer: {
    marginTop: '20px',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: '0 auto',
  },
  formHeading: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#ff6600',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  text:{
  fontSize:'20px'
  },
  textUpi:{
    fontSize:'30px',
    fontWeight:'600',
    },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    marginBottom: '15px',
  },
  textarea: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    marginBottom: '15px',
    minHeight: '100px',
  },
  submitButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#ff6600',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  successMessage: {
    marginTop: '20px',
    fontSize: '16px',
    color: '#28a745',
  },
};

export default Donate;

import React from 'react';
import './Home.css';

const Home = () => {
  
  



  return (
    <div className="home-container">
<div class="banner">
    <img src="/home/img_for_lap.jpg" alt="Banner" class="banner-image" />
</div>


        <div style={{
  marginBottom: '30px',
  padding: '30px',
  textAlign: 'center',
  backgroundColor: '#f0f0f0',
  borderRadius: '15px',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)', // Adjusted shadow
  animation: 'fadeIn 1.5s ease-in-out',
}}>
  <h2 style={{
    fontSize: '28px',
    color: '#ff6600', // Main color
    marginBottom: '20px',
    animation: 'slideIn 1.2s ease-in-out',
    fontFamily: 'Arial, sans-serif',
  }}>
   
  </h2>
  <p style={{
    fontSize: '20px',
    color: '#333', // Darker text color for contrast
    lineHeight: '1.8',
    animation: 'fadeInUp 1.8s ease-in-out',
    margin: '0 auto',
    width: '80%',
    fontFamily: 'Arial, sans-serif',
  }}>
    Cow is our cultural member; none of our cultural or religious values start or end without a cow. We started with 1 cow, Nanda, and gradually this number is reaching 200+. We prefer exchanging milk with cows' grass from donors, motivating us not to do business but to live like a family in a commercial and intellectual world.
  </p>

</div>

<style>
{`
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes slideIn {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
  }

  @keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
`}
</style>




      <div style={{
  marginBottom: '30px',
  padding: '30px',
  textAlign: 'center',
  backgroundColor: '#f0f0f0', // Light green background
  borderRadius: '15px',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)', // Shadow with main color
  animation: 'zoomIn 1.5s ease-in-out',
}}>
  <h2 style={{
    fontSize: '28px',
    color: '#ff6600', // Main color
    marginBottom: '20px',
    animation: 'bounceIn 1.2s ease-in-out',
    fontFamily: 'Arial, sans-serif',
  }}>
    NANDA NANDI GAUSHALA
  </h2>
  <p style={{
    fontSize: '20px',
    color: '#333', // Darker text color for contrast
    lineHeight: '1.8',
    animation: 'fadeInUp 1.8s ease-in-out',
    margin: '0 auto',
    width: '80%',
    fontFamily: 'Arial, sans-serif',
  }}>
    Our purpose is to adopt and protect indigenous cows (Bos Indicus).Come together to serve holy cows with your <strong>Tan Man and Dhan</strong> to stay  blissful mindful and full of life. Our primary motive is not to sell cow's milk, but to keep our Desi cows healthy without any conditions. Usually, people milk the cows and sell the calves to others, but we do not destroy the cow's family. We have not sold a single cow till now.
  </p>

</div>

<style>
{`
  @keyframes zoomIn {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
  }

  @keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }

  @keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
`}
</style>




<div style={{
  padding: '30px',
  backgroundColor: '#f4f4f4',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  gap: '20px',
  animation: 'fadeIn 1.5s ease-in-out',
}}>
  {/* Gau Seva as Anna Daan Card */}
  <div style={{
  width: '300px',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  border: '2px solid #026817',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
  padding: '20px',
  textAlign: 'center',
  animation: 'fadeInUp 1s ease-in-out',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.3)',
  },
}}>
  <div style={{ marginBottom: '15px', }}>
    <img src="\home\Ann_daan.jpeg" alt="Anna Daan" style={{ width: '100%', height: '150px',backgroundSize:'cover',borderRadius:'10px' }} />
  </div>
  <h3 style={{ fontSize: '22px', color: '#ff6600', margin: '15px 0' }}>Anna Daan</h3>
  <p style={{ fontSize: '18px', color: '#333' }}>
    Providing food for cows. Your contribution helps in offering nutritious meals.
  </p>
</div>

  {/* Chara Daan (Fodder Service) Card */}
  <div style={{
  width: '300px',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  border: '2px solid #026817',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
  padding: '20px',
  textAlign: 'center',
  animation: 'fadeInUp 1s ease-in-out',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.3)',
  },
}}>
  <div style={{ marginBottom: '15px', }}>
    <img src="\home\Chara_Daan.jpeg" alt="Chara Daan" style={{ width: '100%', height: '150px',backgroundSize:'cover',borderRadius:'10px' }} />
  </div>
  <h3 style={{ fontSize: '22px', color: '#ff6600', margin: '15px 0' }}>Chara Daan</h3>
  <p style={{ fontSize: '18px', color: '#333' }}>
  Supporting fodder service for cows. Your support ensures a steady supply of food.
  </p>
</div>

  {/* Jeevan Seva Card */}
  <div style={{
  width: '300px',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  border: '2px solid #026817',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
  padding: '20px',
  textAlign: 'center',
  animation: 'fadeInUp 1s ease-in-out',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.3)',
  },
}}>
  <div style={{ marginBottom: '15px', }}>
    <img src="home\jeevanSeva_img.jpeg" alt="Jeevan Seva" style={{ width: '100%', height: '150px',backgroundSize:'cover',borderRadius:'10px' }} />
  </div>
  <h3 style={{ fontSize: '22px', color: '#ff6600', margin: '15px 0' }}>Jeevan Seva</h3>
  <p style={{ fontSize: '18px', color: '#333' }}>
  Saving cows from slaughter and providing them with a safe haven. Your help is crucial.
  </p>
</div>

  {/* Madhav Seva Card */}
  <div style={{
  width: '300px',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  border: '2px solid #026817',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
  padding: '20px',
  textAlign: 'center',
  animation: 'fadeInUp 1s ease-in-out',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.3)',
  },
}}>
  <div style={{ marginBottom: '15px', }}>
    <img src="\home\Madv_Seva.jpeg" alt="Anna Daan" style={{ width: '100%', height: '150px',backgroundSize:'cover',borderRadius:'10px' }} />
  </div>
  <h3 style={{ fontSize: '22px', color: '#ff6600', margin: '15px 0' }}>Madhav Seva</h3>
  <p style={{ fontSize: '18px', color: '#333' }}>
  General service and support for the well-being of cows. Join us in our mission.
  </p>
</div>
</div>

<style>
{`
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
`}
</style>






<div style={{
  marginBottom: '30px',
  padding: '30px',
  textAlign: 'center',
  backgroundColor: '#f4f4f4',
  borderRadius: '15px',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
  animation: 'fadeIn 1.5s ease-in-out',
}}>
  <h2 style={{
    fontSize: '28px',
    color: '#ff6600',
    marginBottom: '20px',
    animation: 'slideIn 1.2s ease-in-out',
    fontFamily: 'Arial, sans-serif',
  }}>
    Support on Special Occasions
  </h2>
  <p style={{
    fontSize: '20px',
    color: '#333',
    lineHeight: '1.8',
    animation: 'fadeInUp 1.8s ease-in-out',
    margin: '0 auto',
    width: '80%',
    fontFamily: 'Arial, sans-serif',
  }}>
    On special occasions such as birthdays, wedding anniversaries, festivals, fasting, and commemorative days, contribute according to your ability. Engage the cow in your life style activities and inspire others to participate in cow service as well.
  </p>
</div>

<style>
{`
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes slideIn {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
  }

  @keyframes fadeInUp {
    0% { transform: translateY(20px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  }
`}
</style>



 
    <div style={{
  margin: '20px',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  borderRadius: '10px',
  boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
  animation: 'fadeIn 1.5s ease-in-out',
  fontFamily: 'Arial, sans-serif',
  textAlign: 'center',
}}>

<h2 style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.8rem',
          color: '#ff6600',
          position: 'relative',
          marginBottom: '20px',
          fontFamily: 'Arial, sans-serif',
        }}>Nanda -Our First Cow</h2>
      <img
        src="\home\first_cow_nanda.jpeg" // Replace with the actual image URL
        alt="Nanda the Cow"
        style={{
         
          maxWidth: '600px',
          width: '100%',
          height: '300px',
          marginRight: '20px',
          borderRadius: '8px',
        }}
      />
      <div style={{
   
  }}>
       
        <p style={{
            fontSize: '20px',
            color: '#333',
            lineHeight: '1.8',
            animation: 'fadeInUp 1.8s ease-in-out',
            margin: '0 auto',
            width: '80%',
          }}>
       Meet Nanda, the first cow in our Gaushala. She is a symbol of our commitment to caring for and protecting these gentle creatures.
       Nanda's presence marks the beginning of our journey in providing a safe and loving home for all our cows. </p>
      </div>
    </div>





<div style={{ padding: '20px', backgroundColor: '#f4f4f4', color: '#333', fontFamily: 'Arial, sans-serif',boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)', }}>
      <div style={{ maxWidth: '1200px', margin: 'auto' }}>
        <h1 style={{ textAlign: 'center', color: '#ff6600', marginBottom: '30px' }}>Our Journey with Nanda Nandi Gaushala</h1>
       

        {/* Special Occasions Section */}
        <p style={{  lineHeight: '1.6', fontSize: '20px', marginBottom: '30px',fontFamily:'Consolas',color:'black' }}>
        In 2009, we began with a humble goal: to sell milk from just one cow. Little did we know, our journey would blossom so quickly. One cow became two, then five, and soon we had nine. Our numbers kept growing, reaching 17, and today, we are blessed to care for over 210 cows across four locations, all under the loving shelter of Nanda Nandi Gaushala. Our Gaushala has evolved over the years, known previously as Nandi Ghar, Prabhu Kripa, and Kehav Kripa. </p>
      <p style={{ lineHeight: '1.6', fontSize: '20px', marginBottom: '30px',fontFamily:'Consolas',color:'black' }}>
        As we devoted ourselves to the service of Gomata, we found that our love for cows was shared by the community. People from different walks of life began visiting us, interacting with the cows, offering food, performing Gau Pooja, and taking home sacred gifts like cow dung and gomutra for special occasions. However, we noticed something troubling—while female cows were cherished for their beauty and milk, male bulls and calves were often overlooked. We faced frequent pressure to sell these male cows, but in our hearts, we knew that every soul is equal, deserving of love and respect.
      </p>
      <p style={{  lineHeight: '1.6', fontSize: '20px', marginBottom: '30px',fontFamily:'Consolas',color:'black' }}>
      This realization deepened our commitment to serving all cows, especially indigenous breeds such as Gir, Sahiwal, Hallikar, Kankrej, Tharparkar, Bargur, and Gangateere. Each cow at Nanda Nandi Gaushala is not just a number—they are family. We give each one a name that reflects their unique personality, names like Krishna, Natkat, Mahadev, Parth, Surya, Kali, Radha, Ganga, Nandi, and many more. This simple act of naming has helped us, and the broader community, form deep emotional connections with each and every cow.</p>
      <p style={{  lineHeight: '1.6', fontSize: '20px', marginBottom: '30px',fontFamily:'Consolas',color:'black' }}>
      As word about our Gaushala spread, we began to receive cows from those who could no longer care for them, as well as cows rescued from slaughter. We welcomed them all with open arms, even as we found ourselves at a crossroads, uncertain of how to manage such immense responsibilities with our limited resources. We often didn’t know where the next meal for our cows would come from. But time and time again, we were humbled by the power of Gomatas and the universe. Support arrived just when we needed it most—from friends, strangers, and even the cows themselves.</p>
      <p style={{  lineHeight: '1.6', fontSize: '20px', marginBottom: '30px',fontFamily:'Consolas',color:'black'}}>
      People have donated water, fodder, feed, salt, ropes, belts, chains, mats, buckets, sheds, medicines, and so much more. These contributions, made possible by the extraordinary kindness of humanity and the blessings of Bhagwathi Kripa and Keshav Kripa, have kept our Gaushala going. </p>
      <p style={{  lineHeight: '1.6', fontSize: '20px', marginBottom: '30px',fontFamily:'Consolas',color:'black'}}>
      Words cannot express our gratitude to all our supporters. This space is a modest attempt to thank the countless donors and volunteers who have provided physical, material, and financial support at crucial moments in the past and those who will continue to do so in the future. From the bottom of our hearts, thank you.
      </p>


        <div style={{
          marginBottom: '30px',
          padding: '30px',
          textAlign: 'center',
          backgroundColor: '#f4f4f4',
          borderRadius: '15px',
          boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)',
          animation: 'fadeIn 1.5s ease-in-out',
        }}>
 <h2 style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.8rem',
          color: '#ff6600',
          position: 'relative',
          marginBottom: '20px',
          fontFamily: 'Arial, sans-serif',
        }}>
          <span style={{ fontSize: '3rem', marginRight: '10px' }}>&#129309;</span>
         
  Gratitude to Our Supporters
          <span style={{ fontSize: '3rem', marginLeft: '10px' }}>&#127873;</span>
          <div style={{
            position: 'absolute',
            bottom: '-5px',
            width: '50%',
            height: '4px',
            background: 'linear-gradient(to right, #026817, #ffffff, #026817)',
          }}></div>
        </h2>

          <p style={{
            fontSize: '20px',
            color: '#333',
            lineHeight: '1.8',
            animation: 'fadeInUp 1.8s ease-in-out',
            margin: '0 auto',
            width: '80%',
          }}>
            We are profoundly grateful to all our supporters—those who have contributed physical, material, and financial support over the years. We extend our heartfelt thanks to the countless donors and volunteers who have stood by us in the past and who continue to support us today. Without your generosity and commitment,
             our journey would not have been possible. We look forward to your continued support as we move forward in our mission to serve Gomata.
          </p>
        </div>


       

        <style>
          {`
            @keyframes fadeIn {
              0% { opacity: 0; }
              100% { opacity: 1; }
            }

            @keyframes slideIn {
              0% { transform: translateX(-100%); }
              100% { transform: translateX(0); }
            }

            @keyframes fadeInUp {
              0% { transform: translateY(20px); opacity: 0; }
              100% { transform: translateY(0); opacity: 1; }
            }
          `}
        </style>

        {/* Additional sections or content can go here */}

      </div>
    </div>




    

     

      <section className="gallery">
        <h2 style={{textAlign:'center'}}>Image Gallery</h2>
        <div className="gallery-images">
          <img src="/gallery/WhatsApp Image 2024-08-23 at 6.35.10 PM (1).jpeg" alt="Gallery 1" />
          <img src="/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM (1).jpeg" alt="Gallery 2" />
          <img src="/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM (2).jpeg" alt="Gallery 3" />
          <img src="/gallery/WhatsApp Image 2024-08-23 at 6.35.45 PM (1).jpeg" alt="Gallery 4" />
       
          <img src="/gallery/WhatsApp Image 2024-08-23 at 7.03.16 PM (1).jpeg" alt="Gallery 6" />
          <img src="/gallery/WhatsApp Image 2024-08-23 at 6.35.46 PM (1).jpeg" alt="Gallery 7" />
        </div>
      </section>


      

      <section className="videos">
        <h2 style={{textAlign:'center'}}>Videos</h2>
        <div className="video-container">
        <iframe
            src="https://www.youtube.com/embed/XGkE_VzTAHk"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 1"
        ></iframe>
        <iframe
            src="https://www.youtube.com/embed/A5NkKCoIo2s"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 2"
        ></iframe>
        <iframe
            src="https://www.youtube.com/embed/ChcQV2wBIPk"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Video 3"
        ></iframe>
    </div>
      </section>

     
    </div>
  );
};

export default Home;

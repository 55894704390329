import React from 'react';

import Slideshow from "./Slideshow";
import TestimonialsPage from "./TestimonialsPage"
import AboutNandaNandi from "./aboutNandaNandi.js"



const AboutUs = () => {
  return (


    <div>

      <Slideshow/>
 <div style={{textAlign:"center"}}>
<div style={{ display: 'flex', flexDirection: 'row',  }}>
<div>
<AboutNandaNandi/>
</div>



      <div style={styles.sideContent}>
        <div style={styles.qrCodeContainer}>
          <h2 style={styles.subtitle}>Donate to Support Our Cause</h2>
          <p style={styles.text}>
            Your contribution helps us in our mission to care for and protect cows. Scan the QR code to donate:
          </p>
          <img
            src="\AboutUs\WhatsApp Image 2024-08-21 at 7.54.35 PM.jpeg" // Replace with the actual QR code image URL
            alt="QR Code"
            style={styles.qrCode}
          />
        </div>

        <div style={styles.donationInfo}>
          <h2 style={styles.subtitle}>How You Can Help</h2>
          <p style={styles.text}>You may donate to support us in the following ways</p>
          <ul style={{
    margin: '30px auto',
    paddingLeft: '0',
    textAlign: 'left',
    listStyleType: 'none',
    width: '70%',
  }}>
    <li style={{
      marginBottom: '15px',
      fontWeight:'500',
      fontSize: '20px',
      color: '#444',// Main color for list items
      animation: 'fadeInUp 3s ease-in-out',
      display: 'flex',
      alignItems: 'center',
    }}>
     contibute for green gras
      
    </li>
    <li style={{
      marginBottom: '15px',
      fontWeight:'500',
      fontSize: '20px',
      color: '#444',  // Main color for list items
      animation: 'fadeInUp 2s ease-in-out',
      display: 'flex',
      alignItems: 'center',
    }}>
     Contibute for dry grass
    </li>
    <li style={{
      marginBottom: '15px',
      fontWeight:'500',
      fontSize: '20px',
      color: '#444', // Main color for list items
      animation: 'fadeInUp 2.2s ease-in-out',
      display: 'flex',
      alignItems: 'center',
    }}>
      Contibute for  bhoosi and daliya
    </li>
    <li style={{
      marginBottom: '15px',
      fontWeight:'500',
      fontSize: '20px',
      color: '#444', // Main color for list items
      animation: 'fadeInUp 2.4s ease-in-out',
      display: 'flex',
      alignItems: 'center',
    }}>
       Contibute for water
    </li>
    <li style={{
      marginBottom: '15px',
      fontWeight:'500',
      fontSize: '20px',
      color: '#444', // Main color for list items
      animation: 'fadeInUp 2.6s ease-in-out',
      display: 'flex',
      alignItems: 'center',
    }}>
       Contibute for food suppliments and medicine
    </li>
    <li style={{
      marginBottom: '15px',
      fontWeight:'500',
      fontSize: '20px',
      color: '#444', // Main color for list items
      animation: 'fadeInUp 2.6s ease-in-out',
      display: 'flex',
      alignItems: 'center',
    }}>
       Contibute for better shed and infrastructure
    </li>
   
    
  </ul>
        </div>

        <div style={styles.contactInfo}>
          <h2 style={styles.subtitle}>Contact Us</h2>
          <p style={styles.text}>
          SRI MADHAV SEVA SANGH (R.)<br />
          NANDA NANDI GAUSALA<br />
          311 Upkar Spring Field, Nerlur- Post Bangaluru 562107<br />
            Phone: <a style={{color:'black'}} href="tel:7847842626" > <strong>7847842626</strong></a>,<a style={{color:'black'}} href="tel:8088817727"> <strong>8088817727</strong></a> <br />
            Email: srimadhavsevasangh@gmail.com
          
          </p>
        </div>
      </div>

      

      <style>
    {`
      @media (max-width: 768px) {
        div[style*="display: flex"] {
          flex-direction: column !important;
        }
      }
    `}
  </style>
    </div>

    <TestimonialsPage/>


    </div>
    
    </div>
   
  
  
    
  );
};

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection:'row',
    flexWrap: 'wrap',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '1400px',
    margin: '0 auto',
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    animation: 'fadeIn 1s ease-in-out',
  },
  mainContent: {
    flex: '0 0 70%',
    paddingRight: '20px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.05)',
    animation: 'slideInLeft 0.5s ease-in-out',
  },
  sideContent: {
    flex: '0 0 30%',
    paddingLeft: '20px',
    boxSizing: 'border-box',
    background: 'linear-gradient(135deg,  #020202)', // Added colors to the gradient
    borderRadius: '10px',
    padding: '20px',
    color: '#fff',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    animation: 'slideInRight 0.5s ease-in-out',
    '@media (max-width: 768px)': {
        flex: '0 0 100%', // Take full width on mobile
        padding: '15px',
        marginTop: '20px', // Add some margin to separate from the content above
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
},
  
  title: {
    fontSize: '32px',
    color: '#026817',
    textAlign: 'center',
    marginBottom: '20px',
    borderBottom: '2px solid #026817 ',
    
    paddingBottom: '10px',
    animation: 'fadeInDown 0.8s ease-in-out',
    display: 'inline-block'
  },
  subtitle: {
    fontSize: '24px',
    maeginTop:'20px',
    color: '#ff6600',
    
    marginBottom: '10px',
    borderBottom: '1px solid #026817',
    paddingBottom: '5px',
    display: 'inline-block',
  },
  text: {
    fontSize: '18px',
    color: '#555',
    textAlign: 'justify',
    marginBottom: '20px',
  },
  list: {
    paddingLeft: '20px',
    marginBottom: '20px',
    
  },
  listItem: {
    fontSize: '18px',
    color: '#333',
    marginBottom: '10px',
    position: 'relative',
    paddingLeft: '20px',
  },
  qrCodeContainer: {
    textAlign: 'center',
    marginTop:'33px',
    marginBottom: '20px',
  },
  qrCode: {
    width: '100%',
    maxWidth: '350px',
    height: 'auto',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  donationInfo: {
    marginBottom: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '10px',
    borderRadius: '5px',
    animation: 'fadeInUp 0.6s ease-in-out',
  },
  contactInfo: {
    marginTop: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '10px',
    borderRadius: '5px',
    animation: 'fadeInUp 0.6s ease-in-out',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  '@keyframes slideInLeft': {
    from: { transform: 'translateX(-100%)' },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes slideInRight': {
    from: { transform: 'translateX(100%)' },
    to: { transform: 'translateX(0)' },
  },
  '@keyframes fadeInDown': {
    from: { transform: 'translateY(-20px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  },
  '@keyframes fadeInUp': {
    from: { transform: 'translateY(20px)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  },
};



export default AboutUs;

import React, { useEffect, useState } from 'react';

const getResponsiveStyles = () => {
  const width = window.innerWidth;
  if (width <= 600) {
    return {
      mainContent: { padding: '0 10px' },
      header: { fontSize: '2rem' },
      icon: { fontSize: '2rem' },
      underline: { height: '2px' },
      text: { fontSize: '1rem', lineHeight: '1.5' },
      list: { gap: '10px' },
      listItem: { fontSize: '1rem' },
      section: { padding: '15px' },
      visitTimes: { fontSize: '16px' }
    };
  } else if (width <= 768) {
    return {
      mainContent: { padding: '0 15px' },
      header: { fontSize: '2.5rem' },
      icon: { fontSize: '2.5rem' },
      underline: { height: '3px' },
      text: { fontSize: '1.1rem', lineHeight: '1.6' },
      list: { gap: '15px' },
      listItem: { fontSize: '1.1rem' },
      section: { padding: '20px' },
      visitTimes: { fontSize: '18px' }
    };
  } else {
    return {
      mainContent: { padding: '0 20px' },
      header: { fontSize: '2.8rem' },
      icon: { fontSize: '3.2rem' },
      underline: { height: '4px' },
      text: { fontSize: '1.1rem', lineHeight: '1.8' },
      list: { gap: '20px' },
      listItem: { fontSize: '1.1rem' },
      section: { padding: '30px' },
      visitTimes: { fontSize: '20px' }
    };
  }
};

const AboutNandaNandi = () => {
  const [styles, setStyles] = useState(getResponsiveStyles);

  useEffect(() => {
    const handleResize = () => setStyles(getResponsiveStyles);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={styles.mainContent}>
      <h1 style={{ ...styles.header, position: 'relative',color:'#ff6600' }}>
        <span style={styles.icon}>&#9997;</span>
        नंदा नंदी गोशाला
        <span style={styles.icon}>&#9997;</span>
        <div style={{
          position: 'absolute',
          bottom: '-5px',
          width: '100%',
          height: styles.underline.height,
          background: 'linear-gradient(to right, #026817, #ffffff, #026817)',
          borderRadius: '2px',
          animation: 'expandLine 1.5s ease-in-out',
        }}></div>
      </h1>

      <p style={{ ...styles.text, textAlign: 'justify', margin: '0 auto', maxWidth: '800px' }}>
        Since 2009, we have been dedicated to preserving indigenous cow breeds and promoting cow seva. Our mission is to provide a loving home for cows and inspire others to join us in this noble cause.
      </p>

      <h2 style={{ ...styles.subtitle, color: '#ff6600', fontSize: '2rem', marginTop: '40px' }}>Our Journey</h2>
      <p style={{ ...styles.text, textAlign: 'justify', margin: '0 auto', maxWidth: '800px' }}>
        Our journey began in 2009 with the humble intention of selling milk from just one cow. In no time, the numbers grew—first to 2, then 5, later to 9, reaching 17, and today we proudly care for over 210 cows across four locations under our Nanda Nandi Gaushala (previously known as Nandi Ghar, Prabhu Kripa, and Keshav Kripa).
      </p>

      <h2 style={{ ...styles.subtitle, color: '#ff6600', fontSize: '2rem', marginTop: '40px' }}>Our Approach</h2>
      <div style={{ ...styles.list, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: styles.list.gap }}>
        <p style={{ ...styles.listItem, background: '#f7f7f7', padding: '0px 0px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s' }}>Protecting indigenous cow breeds</p>
        <p style={{ ...styles.listItem, background: '#f7f7f7', padding: '0px 0px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s' }}>Providing a family environment for cows</p>
        <p style={{ ...styles.listItem, background: '#f7f7f7', padding: '0px 0px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s' }}>Encouraging cow adoption</p>
        <p style={{ ...styles.listItem, background: '#f7f7f7', padding: '0px 0px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s' }}>Promoting cow seva as a way of life</p>
      </div>

      <h2 style={{ ...styles.subtitle, color: '#ff6600', fontSize: '2rem', marginTop: '40px' }}>Our Branches</h2>
      <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
        <li style={{ fontSize: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: '24px', color: '#026817', marginRight: '10px' }}></span>
          Nanda Nandi Gaushala
        </li>
        <li style={{ fontSize: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: '24px', color: '#026817', marginRight: '10px' }}></span>
          Nandi Ghar Madhakshira
        </li>
        <li style={{ fontSize: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: '24px', color: '#026817', marginRight: '10px' }}></span>
          Prabhu Kripa Kamblipura Near Attibele
        </li>
        <li style={{ fontSize: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: '24px', color: '#026817', marginRight: '10px' }}></span>
          Keshav Kripa Daiknikote Tamilnadu
        </li>
       
      </ul>

      <div style={{ marginBottom: '30px', padding: '30px', textAlign: 'center', backgroundColor: '#f4f4f4', borderRadius: '15px', boxShadow: '0px 0px 25px rgba(2, 104, 23, 0.2)', animation: 'fadeIn 1.5s ease-in-out' }}>
        <h2 style={{ fontSize: '28px', color: '#ff6600', marginBottom: '20px', animation: 'slideIn 1.2s ease-in-out', fontFamily: 'Arial, sans-serif' }}>
          Support on Special Occasions
        </h2>
        <p style={{ fontSize: styles.visitTimes.fontSize, color: '#333', lineHeight: '1.8', animation: 'fadeInUp 1.8s ease-in-out', margin: '0 auto', width: '80%', fontFamily: 'Arial, sans-serif' }}>
          On special occasions such as birthdays, wedding anniversaries, festivals, fasting, and commemorative days, contribute according to your ability. Engage the cow in your life style activities and inspire others to participate in cow service as well.
        </p>
      </div>

    

  
    </div>
  );
};

export default AboutNandaNandi;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import PhotoGallery from './pages/PhotoGallery';
import ContactUs from './pages/ContactUs';


import Donate from './pages/Donate';
import StickyButtons from './pages/StickyButtons';

import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/photo-gallery" element={<PhotoGallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
         
        
          <Route path="/donate" element={<Donate />} />
        </Routes>
      </main>
      <StickyButtons/>
      <Footer />
    </Router>
  );
}

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const getNavLinkStyle = (path) => {
    return location.pathname === path
      ? { ...styles.navLink, ...styles.navLinkActive }
      : styles.navLink;
  };

  return (
    <header style={styles.header}>
       <div className="logo-container">
        <img   src="\home\logo_main.png" alt="Logo" className="logoImageHead" />
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
         Menu
      </button>
      <nav className={`nav ${isMenuOpen ? 'show' : ''}`} style={styles.nav}>
        <ul style={styles.navList}>
          <li><img src="\home\logo_main.png " alt="Logo" style={styles.logoImageHead} /></li>
          <li><Link to="/" style={getNavLinkStyle('/')} onClick={closeMenu}>Home</Link></li>
        
          <li><Link to="/photo-gallery" style={getNavLinkStyle('/photo-gallery')} onClick={closeMenu}>Photo Gallery</Link></li>
          <li><Link to="/about-us" style={getNavLinkStyle('/about-us')} onClick={closeMenu}>About Us</Link></li>
          <li><Link to="/contact-us" style={getNavLinkStyle('/contact-us')} onClick={closeMenu}>Contact Us</Link></li>
          <li><Link to="/donate" style={getNavLinkStyle('/donate')} onClick={closeMenu}>Donate</Link></li>
        </ul>
      </nav>
    </header>
  );
}

function Footer() {
  return (
    <footer style={styles.footer} >
      <div style={styles.container} className='footer-container'>
        <div style={styles.logo}>
          <img src="\home\logo_main.png" alt="Logo" style={styles.logoImage} />
        </div>
        <div style={styles.links}>
          <h4 style={{fontSize:'20px'}}>Useful Links</h4>
          <ul style={styles.list}>
            <li><Link to="/" style={styles.link}>Home</Link></li>
           
           
            <li><Link to="/photo-gallery" style={styles.link}>Photo Gallery</Link></li>
            <li><Link to="/about-us" style={styles.link}>About Us</Link></li>
            <li><Link to="/contact-us" style={styles.link}>Contact Us</Link></li>
            <li><Link to="/donate" style={styles.link}>Donate</Link></li>
          </ul>
        </div>
        <div style={styles.contact}>
          <h4>Contact Us</h4>
          <p style={styles.address}>Nanda Nandi Gaushala</p>
          <p style={styles.contactDetail}>311 Upkar Spring Field, Nerlur- Post Bangaluru 562107</p>
          <p style={styles.contactDetail}>Phone: <a style={{ color: 'white ' }} href="tel:917847842626"> <strong>7847842626</strong> </a>, <a style={{ color: 'white ' }} href="tel:917847842626"><strong>8088817727</strong> </a></p>
          <p>Email: srimadhavsevasangh@gmail.com</p>
          <div style={styles.socialIcons}>
           
            <a href="https://www.facebook.com" style={styles.icon} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://twitter.com" style={styles.icon} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href=" https://www.instagram.com/sri.madhav.seva.sangh?igsh=YzljYTk1ODg3Zg==" style={styles.icon} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.linkedin.com" style={styles.icon} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="https://www.youtube.com/@Nandanandigaushala" style={styles.icon} aria-label="YouTube" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
      </div>
      <hr />
      <p>&copy; 2024 Nanda Nandi Gaushala. All rights reserved.</p>
    </footer>
  );
}

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#fff',
  },
  logoContainer: {
    flex: '0 0 auto',
    marginRight: '20px',
  },
  logoImageHead: {
    maxWidth: '150px',
   
  },
  nav: {
    

    flex: '1',
    justifyContent:'center',
    textAlign: 'center',
  },
  
  navList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navLink: {
    color: '#ff6600',
    textDecoration: 'none',
    fontSize: '20px',
    padding: '10px 15px',
    margin: '0 5px',
    transition: 'color 0.3s',
  },
  navLinkActive: {
    backgroundColor: '#ff6600',
    color: '#fff',
    borderRadius: '5px',
  },
  footer: {
    
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px 0',
    textAlign: 'center',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    textAlign:'center',
    
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  logo: {
    flex: '3',
    padding:'10px'
   
  },
  logoImage: {
    maxWidth: '250px',
  },
  links: {
    flex: '2',
    justifyContent:'center',
    textAlign: 'start',
    padding:'10px'
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '20px',
    marginTop: '3px',
  },
  contact: {
    flex: '3',
    textAlign: 'start',
    fontSize: '20px',
    padding:'10px'
  },
  contactDetail: {
    margin: '5px 0',
    fontSize: '20px',
    marginTop: '3px',
  },
  address: {
    marginTop: '10px',
    fontSize: '20px',
  },
  socialIcons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  icon: {
    color: '#ff6600',
    fontSize: '24px',
    textDecoration: 'none',
  },
  
};

export default App;

// src/DonationForm.js
import React from 'react';
import emailjs from 'emailjs-com';

// Define styles for the form
const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    backgroundColor: '#f9f9f9'
  },
  input: {
    marginBottom: '15px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box'
  },
  textarea: {
    marginBottom: '15px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    width: '100%',
    height: '150px',
    boxSizing: 'border-box'
  },
  submitButton: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#ff6600',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    width: '100%'
  }
};

const DonationForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_azsi16m', 'template_7nn9bok', '#donation-form', '-J5aznDBbENygx1cJ')
      .then((response) => {
        alert('Enquiry sent successfully!');
        e.target.reset(); // Reset form fields after successful submission
      })
      .catch((error) => {
        alert('Failed to send enquiry. Please try again.');
        console.error('EmailJS error:', error);
      });
  };

  return (
<form id="donation-form" onSubmit={handleSubmit} style={styles.form}>
  <input 
    type="text" 
    name="name" 
    placeholder="Full Name" 
    style={styles.input} 
    required 
    pattern="[A-Za-z\s]+" 
    title="Name should only contain letters and spaces."
  />
  
  <input 
    type="text" 
    name="mobile" 
    placeholder="Mobile No" 
    style={styles.input} 
    required 
    pattern="\d{10}" 
    title="Mobile number should be exactly 10 digits."
  />
  
  <input 
    type="email" 
    name="email" 
    placeholder="Email" 
    style={styles.input} 
    required 
  />
  
  <input 
    type="text" 
    name="pan" 
    placeholder="PAN No" 
    style={styles.input} 
    pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" 
    title="PAN number should be in the format: ABCDE1234F" 
  />
  
  <select 
    name="donationType" 
    style={styles.input} 
    required
  >
    <option value="">Select Donation Type</option>
    <option value="general">General Donation</option>
    <option value="fodder">Fodder Donation</option>
    <option value="cattle">Cattle Care</option>
  </select>
  
  <select 
    name="exemptionType" 
    style={styles.input} 
    required
  >
    <option value="">Select Exemption Type</option>
    <option value="80G">80G Exempt</option>
    <option value="non80G">Non-Exempt</option>
  </select>
  
  <input 
    type="number" 
    name="amount" 
    placeholder="Amount" 
    style={styles.input} 
    required 
    min="1"
  />
  
  <input 
    type="text" 
    name="transactionId" 
    placeholder="Transaction ID" 
    style={styles.input} 
    required 
    pattern="[A-Za-z0-9]+" 
    title="Transaction ID should only contain alphanumeric characters."
  />
  
  <input 
    type="text" 
    name="paymentMethod" 
    placeholder="Payment Method (UPI/Net Banking)" 
    style={styles.input} 
    required 
    pattern="[A-Za-z\s]+" 
    title="Payment Method should only contain letters and spaces."
  />
  
  <input 
    type="date" 
    name="paymentDate" 
    placeholder="Date of Payment" 
    style={styles.input} 
    required 
  />
  
  <textarea 
    name="message" 
    placeholder="Message (if any)" 
    style={styles.textarea}
  ></textarea>

<label style={{ marginBottom: '5px', fontWeight: 'bold' ,padding:'5px'}}>Upload Payment Screenshot:</label>
            <input 
              type="file" 
              name="paymentScreenshot" 
              required 
              accept="image/*" 
              style={styles.fileInput}
            />
  
  <button 
    type="submit" 
    style={styles.submitButton}
  >
    Submit Donation
  </button>
</form>

  );
};

export default DonationForm;

import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_azsi16m', 'template_0et4ooc', form.current, '-J5aznDBbENygx1cJ')
      .then((result) => {
          alert('Message sent successfully!');
      }, (error) => {
          alert('Failed to send message, please try again.');
      });
  };

  return (
    <div style={{textAlign:"center"}}>
      <div style={{ width: "100%", height: "400px" }}>
      <iframe
        title="Nanda Nandi Gaushala Map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7774.920581183883!2d77.7211651!3d12.7838636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6f6779e01819%3A0x25ddd53e6fbd50b2!2sNanda%20Nandi%20Gaushala!5e0!3m2!1sen!2sin!4v1692812511441!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  <div style={styles.container}>
      <div style={styles.address}>
        <h2 style={styles.heading}>Reach Us</h2>
        <p style={styles.text}>
          Sri Madhav Seva Sangh<br />
          Nanda Nandi Gaushala<br />
          311 Upkar Spring Field, Nerlur- Post Bangaluru 562107<br />
          Phone: <a style={{color:'white'}} href="tel:7847842626">+91- 7847842626</a>, <a style={{color:'white'}} href="tel:8088817727">+91- 8088817727</a> <br />
          Email: srimadhavsevasangh@gmail.com
        </p>
      </div>
      <div style={styles.formContainer}>
        <h2 style={styles.heading}>Contact Us</h2>
        <form ref={form} onSubmit={sendEmail} style={styles.form}>
  <div style={styles.formGroup}>
    <input
      type="text"
      name="name"
      style={styles.input}
      placeholder="Name"
      required
      pattern="[A-Za-z\s]+"
      title="Name should only contain letters and spaces."
    />
  </div>
  <div style={styles.formGroup}>
    <input
      type="email"
      name="email"
      style={styles.input}
      placeholder="Email"
      required
    />
  </div>
  <div style={styles.formGroup}>
    <textarea
      name="message"
      style={styles.textarea}
      placeholder="Message"
      required
      minLength="5"
      title="Message should be at least 5 characters long."
    ></textarea>
  </div>
  <button type="submit" style={styles.button}>Send Message</button>
</form>

      </div>
    </div>
    </div>
  
  );
};

const styles = {
  container: {
    display: 'flex',
    
    justifyContent: 'space-between',
    padding: '40px',
    boxSizing: 'border-box',
    background: '#f8f9fa',
    borderRadius: '10px',
    paddingTop:'60px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    flexWrap: 'wrap', // Allow wrapping on smaller screens
    flexDirection: 'row', // Row direction by default
  },
  address: {
    
    flex:'1',
    padding: '20px',
    background: '#3a483d',
    color: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px', // Space at the bottom for mobile
    width: '100%',
    boxSizing: 'border-box',
  },
  formContainer: {
  
    flex:'1',
    padding: '20px',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center content vertically
    marginBottom: '20px', // Space at the bottom for mobile
    width: '100%',
    boxSizing: 'border-box',
  },
  heading: {
    marginBottom: '20px',
    color:'#ff6600',
  
    fontSize: '24px',
    borderBottom: '2px solid #026817',
    paddingBottom: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '12px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '10px',
    backgroundColor:'#e2e5d9',
  },
  textarea: {
    padding: '12px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '100px',
    backgroundColor:'#e2e5d9',
  },
  text:{
     fontSize:'20px',
  },
  button: {
    padding: '12px 20px',
    fontSize: '16px',
    color: '#fff',
    background: '#ff6600',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'background 0.3s ease',
    alignSelf: 'center', // Align the button to the start (left)
  },

  // Add media queries for responsiveness
  '@media (max-width: 768px)': {
    container: {
      flexDirection: 'column', 
                     // Stack items vertically on smaller screens
    },
    address: {
      width: '100%', // Full width on mobile
    },
    formContainer: {
      width: '100%', // Full width on mobile
    },
  },
};

export default ContactUs;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BookYourSeva.css';
import BookYourSevaModal from './BookYourSevaModal';



    const fodderData = [
        {
          type: 'Fodder',
          description: 'Support our Gaushaala by donating fodder to ensure our cows receive the nutritious feed they need. Your contribution plays a vital role in their health and happiness, helping them thrive.',
          image: '/home/Chara_Daan.jpeg'
        },
        {
          type: 'Monthly care of a cow',
          description: 'Monthly care of a cow involves providing regular, nutritious feed, ensuring clean water, and routine health check-ups. This comprehensive care maintains their well-being and happiness. Your support helps us deliver consistent, quality care every month.',
          image: '/gallery/WhatsApp Image 2024-08-23 at 6.35.11 PM (2).jpeg'
        },
        {
          type: 'Medicines',
          description: 'Providing medicines to our cows is crucial for their health and well-being. Regular treatments prevent diseases and address any health issues promptly. Your support helps ensure that our cows receive the necessary medications for a healthy, happy life.',
          image: '/home/ayurvedic-chyawanprash-is-powerful-immunity-booster-natural-health-supplement-served-antique-bowl-with-ingredients-moody-background-selective-focus.jpg'
        },
        {
            type: 'Gaudaan',
            description: 'Gaudaan involves donating cows in need of rest or special care to a shelter. Your participation helps provide these animals with a safe, loving environment. At our Gaushaala, your support ensures they are cherished and well-cared-for, upholding a tradition of kindness.',
            image: '/seva/images (8).jpg'
          },
          {
            type: 'Food distribution',
            description: 'At our Gaushaala, we ensure the well-being of our cows with regular, nutritious meals as a key part of our daily routine. Thanks to generous donations, we provide fresh feed and clean water, keeping every cow healthy and happy. Your support helps sustain their overall well-being.',
            image: '/gallery/WhatsApp Image 2024-08-23 at 6.58.59 PM (2).jpeg'
          },
    
          {
            type: 'Donate Any Amount',
            description: 'Donating to our Gaushaala, regardless of the amount, greatly impacts the lives of our cows. Your contribution provides essential food, shelter, and medical care, ensuring their comfort and happiness. Every donation supports our mission to protect and nurture these cherished animals.',
            image: '/seva/download (15).jpg'
          }
      ];

      const BookYourSeva = () => {
        const [selectedSevaType, setSelectedSevaType] = useState(null); // State to manage selected seva type
        const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
      
        const handleAdoptClick = (sevaType) => {
          setSelectedSevaType(sevaType); // Set the selected seva type
          setIsModalOpen(true); // Open the modal
        };
      
        const handleCloseModal = () => {
          setIsModalOpen(false); // Close the modal
          setSelectedSevaType(null); // Reset the selected seva type
        };
      
        return (
          <div className="container">
            <h1 style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '2.5rem',
              color: '#ff6600',
              position: 'relative',
              marginBottom: '20px',
              fontFamily: 'Georgia',
            }}>
              <span style={{ fontSize: '3rem', marginRight: '10px' }}>🤝</span>
              Donate
              <span style={{ fontSize: '3rem', marginLeft: '10px' }}>🤝</span>
              <div style={{
                position: 'absolute',
                bottom: '-5px',
                width: '100%',
                height: '4px',
                background: 'linear-gradient(to right, #026817, #ffffff, #026817)',
              }}></div>
            </h1>
            <div className="grid">
              {fodderData.map((seva, index) => (
                <div key={index} className="card">
                  <img src={seva.image} alt={seva.name} />
                  <h2>{seva.type}</h2>
                  <p>{seva.description}</p>
                  <button onClick={() => handleAdoptClick(seva.type)}>
                    Donate {seva.type}
                  </button>
                </div>
              ))}
            </div>
      
            {isModalOpen && (
              <BookYourSevaModal sevaType={selectedSevaType} onClose={handleCloseModal} />
            )}
          </div>
        );
      };
      
      export default BookYourSeva;
